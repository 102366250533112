// Collection of navigatino helper composables
import { ref, onMounted, onActivated } from 'vue';

namespace NavigationComposables {
    /**
     * Returns a ref that will be auto focused on mount. Difference from vFocus is 
     * that this also supports KeepAlive activated hook.
     */
    export function useAutoFocus() {
        const elRef = ref<HTMLElement>(null);
        onMounted(() => {
            elRef.value?.focus();
        });

        onActivated(() => {
            elRef.value?.focus();
        })

        return elRef;
    }
}

export default NavigationComposables;